<template>
  <v-container class="general">
    <page-title :component="'GeneratedMessageListPage'">
      <template v-slot:actions>
        <LanguageSwitcher />
      </template>
    </page-title>

    <v-card>
      <div class="grey lighten-5">
        <v-layout row wrap class="pt-2 pb-3 px-4 align-items-center">
          <v-flex xs12 class="mb-4">
            <v-layout row no-wrap class="align-items-center">
              <v-autocomplete
                v-model="messageTemplate"
                :items="messageTemplates"
                return-object
                item-text="name"
                item-value="id"
                @input="
                  page = 1;
                  listGroupUserMessages();
                "
                :disabled="isLoading"
                :label="
                  $t('group_generated_messages_list.message_template_label')
                "
                clearable
                attach
                hide-details
              >
                <template slot="item" slot-scope="data">
                  <v-layout row nowrap class="align-items-center py-2">
                    <v-flex xs10>{{ data.item.name }}</v-flex>
                    <v-flex xs2 class="text-right caption">{{
                      $t(`type-${data.item.type}`)
                    }}</v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
              <v-btn
                round
                class="ma-0 ml-2 sw-accent-bg sw-on-accent text-none"
                :loading="messageTemplate && messageTemplate.id && isLoading"
                :disabled="
                  !messageTemplate ||
                  !messageTemplate.id ||
                  messageTemplate.type !== 'email'
                "
                @click="updateEmailStatuses()"
                >{{
                  $t(
                    "group_generated_messages_list.update_email_statuses_button_text",
                  )
                }}</v-btn
              >
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-layout row class="align-items-center">
              <v-flex xs4 class="pr-1">
                <v-text-field
                  v-model="filters.search"
                  :label="$t('group_generated_messages_list.search_label')"
                  :disabled="isLoading"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs2 class="px-1">
                <v-select
                  v-model="filters.status"
                  :items="deliveryStatusList"
                  item-value="value"
                  item-text="name"
                  :label="
                    $t('group_generated_messages_list.delivery_status_label')
                  "
                  clearable
                  attach
                  hide-details
                  :disabled="isLoading"
                >
                </v-select
              ></v-flex>
              <v-flex xs3 class="px-1">
                <date-picker
                  v-model="filters.date_from"
                  type="date"
                  :first-day-of-week="1"
                  :format="`[${$t('common.date_picker_from')}:] DD-MMMM-YYYY`"
                  :lang="datePickerLang"
                  :not-after="filters.date_to"
                  :editable="false"
                  :placeholder="$t('common.date_picker_from')"
                  width="100%"
                  :disabled="isLoading"
                ></date-picker
              ></v-flex>
              <v-flex xs3 class="pl-1">
                <date-picker
                  v-model="filters.date_to"
                  type="date"
                  :first-day-of-week="1"
                  :format="`[${$t('common.date_picker_to')}:] DD-MMMM-YYYY`"
                  :lang="datePickerLang"
                  :not-before="filters.date_from"
                  :editable="false"
                  :placeholder="$t('common.date_picker_to')"
                  width="100%"
                  :disabled="isLoading"
                ></date-picker
              ></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 class="py-3 px-4 table-header">
            <v-layout class="row no-wrap align-items-center pr-4">
              <v-flex xs5>{{
                $t("group_generated_messages_list.guest_label")
              }}</v-flex>
              <v-flex xs2>{{
                $t("group_generated_messages_list.released_at_label")
              }}</v-flex>
              <v-flex xs3>
                <TranslatableTooltip
                  :content="
                    $t('group_generated_messages_list.status_label_tooltip')
                  "
                  center
                >
                  {{ $t("group_generated_messages_list.status_label") }}
                </TranslatableTooltip>
              </v-flex>
              <v-flex xs2 class="text-center">{{
                $t("group_generated_messages_list.language_label")
              }}</v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-card>

    <transition name="fade" mode="out-in">
      <v-layout
        v-if="
          !isLoading &&
          !messages.length &&
          messageTemplate &&
          messageTemplate.id
        "
        class="row wrap text-center"
      >
        <v-flex xs12 class="mt-4 sw-h5 font-weight-light">
          {{ $t("group_generated_messages_list.empty_list_text") }}
        </v-flex>
      </v-layout>
    </transition>

    <v-layout
      v-if="messages && messages.length"
      row
      wrap
      :class="{ loading: isLoading }"
    >
      <v-flex xs12>
        <v-expansion-panel>
          <v-expansion-panel-content
            v-for="(message, i) in messages"
            :key="'message-' + i"
          >
            <template slot="header">
              <v-layout class="row no-wrap align-items-center py-1">
                <template v-if="!message.recipient">
                  <v-flex xs12>
                    <span class="grey--text">
                      {{ $t("group_generated_messages_list.no_recipient") }}
                    </span>
                  </v-flex>
                </template>
                <template v-if="message.recipient">
                  <v-flex xs5>
                    <span class="font-weight-bold">
                      {{ message.recipient.first_name }}
                      {{ message.recipient.last_name }}</span
                    >
                  </v-flex>
                  <v-flex xs2> {{ formatDate(message.released_at) }} </v-flex>
                  <v-flex xs3 class="text-center">
                    <v-tooltip top>
                      <font-awesome-icon
                        slot="activator"
                        :icon="['fas', 'circle']"
                        :class="`status-${
                          message.message_delivery_status || 'unknown'
                        }`"
                      />
                      <div class="tooltip-content">
                        <div>
                          {{
                            $t(
                              "group_generated_messages_list.status_tooltip_status_label",
                            )
                          }}
                          {{ message.provider_message_delivery_status || "-" }}
                        </div>
                        <div>
                          {{
                            $t(
                              "group_generated_messages_list.status_tooltip_report_label",
                            )
                          }}
                          {{ message.provider_message_report || "-" }}
                        </div>
                      </div>
                    </v-tooltip>
                  </v-flex>
                  <v-flex xs2 class="text-center">
                    <span
                      :class="`flag-icon flag-icon-${getLangIcon(
                        message.language,
                      )}`"
                    ></span>
                  </v-flex>
                </template>
              </v-layout>
            </template>
            <v-card color="grey lighten-5">
              <v-card-text class="pa-4">
                <v-layout
                  v-if="message.recipient && message.recipient.id"
                  row
                  wrap
                  class="mb-5"
                >
                  <v-flex xs12>
                    <v-layout>
                      <v-flex xs4>{{
                        $t("group_generated_messages_list.email_label")
                      }}</v-flex>
                      <v-flex xs8>{{
                        $t("group_generated_messages_list.phone_label")
                      }}</v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout row class="align-items-center">
                      <v-flex xs4>
                        {{ message.recipient.email }}
                        <a
                          :href="`/#/groups/${groupId}/members/${message.recipient.id}/edit`"
                          target="_blank"
                          class="ml-2"
                        >
                          <font-awesome-icon
                            icon="external-link-alt"
                            class="sw-accent"
                          />
                        </a>
                      </v-flex>
                      <v-flex xs8>
                        <template v-if="message.recipient.phone">
                          <span
                            v-if="message.recipient.phone_code"
                            class="mr-2"
                          >
                            +{{ message.recipient.phone_code }}</span
                          >{{ message.recipient.phone }}
                        </template>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout v-if="message.subject" row wrap class="mb-5">
                  <v-flex xs12 class="mb-4 sw-h5">{{
                    $t("group_generated_messages_list.subject")
                  }}</v-flex>
                  <v-flex xs12>{{ message.subject }}</v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <div class="mb-4 sw-h5">
                      {{ $t("group_generated_messages_list.body") }}
                    </div>
                    <div
                      v-html="unparseContent(message.body)"
                      class="sw-content-typography message-template-body"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="pt-5">
      <v-flex v-if="totalPages > 1" xs12 class="text-center">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listGroupUserMessages()"
          :disabled="isLoading"
        ></v-pagination>
      </v-flex>
      <v-flex xs12 class="text-right">
        {{ $t("common.export") }}:
        <!-- PDF -->
        <v-tooltip top>
          <v-btn
            slot="activator"
            icon
            class="ma-0"
            @click="exportMessages('pdf')"
            :disabled="isLoading"
          >
            <font-awesome-icon
              icon="file-pdf"
              :class="!isLoading ? 'sw-accent' : 'grey-text'"
            />
          </v-btn>
          <span>{{ $t("download") }} PDF</span>
        </v-tooltip>
        <!-- HTML -->
        <v-tooltip top>
          <v-btn
            slot="activator"
            icon
            class="ma-0"
            @click="exportMessages('html')"
            :disabled="isLoading"
          >
            <font-awesome-icon
              icon="file-code"
              :class="!isLoading ? 'sw-accent' : 'grey-text'"
            />
          </v-btn>
          <span>{{ $t("download") }} HTML</span>
        </v-tooltip>
        <!-- XLSX -->
        <v-tooltip top>
          <v-btn
            slot="activator"
            icon
            class="ma-0"
            @click="exportMessages('xlsx')"
            :disabled="isLoading"
          >
            <font-awesome-icon
              icon="file-excel"
              :class="!isLoading ? 'sw-accent' : 'grey-text'"
            />
          </v-btn>
          <span>{{ $t("download") }} XLS</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import "flag-icon-css/css/flag-icon.css";
import { languageIcons } from "@/language-icons.json";
import fileDownload from "js-file-download";
import axios from "axios";

export default {
  data: () => ({
    isLoading: false,
    messages: [],
    messageTemplates: [],
    messageTemplate: {},
    page: 1,
    totalPages: 0,
    totalVisible: 5,
    perPage: 16,
    timeout: null,
    filters: {},
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    queryTemplateId() {
      return this.$route.query.template_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    datePickerLang() {
      return {
        days: this.$t("datePickerDays").split(","),
        months: this.$t("datePickerMonths").split(","),
      };
    },
    deliveryStatusList() {
      return [
        {
          name: this.$t("group_generated_messages_list.status_sent"),
          value: ["sent"],
        },
        {
          name: this.$t("group_generated_messages_list.status_not_sent"),
          value: ["not_sent"],
        },
        {
          name: this.$t("group_generated_messages_list.status_unknown"),
          value: ["unknown", "new"],
        },
      ];
    },
    languageIcons() {
      return languageIcons || [];
    },
    transformedFilters() {
      return {
        template_id: this.messageTemplate ? this.messageTemplate.id : null,
        search: this.filters.search || null,
        status: (this.filters.status || []).filter((val) => val),
        date_from: !this.filters.date_from
          ? null
          : moment
              .tz(this.filters.date_from, this.userTimezone)
              .format("Y-MM-DD"),
        date_to: !this.filters.date_to
          ? null
          : moment
              .tz(this.filters.date_to, this.userTimezone)
              .format("Y-MM-DD"),
      };
    },
  },
  components: {
    DatePicker,
    LanguageSwitcher,
  },
  watch: {
    appContentLanguage: {
      handler() {
        this.listMessageTemplates();
      },
      immediate: true,
    },
    filters: {
      handler() {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.page = 1;

          this.listGroupUserMessages();
        }, 500);
      },
      deep: true,
    },
  },
  methods: {
    async listMessageTemplates() {
      try {
        const params = [
          this.groupId,
          {
            lang: this.appContentLanguage,
            page: 1,
            per_page: 1000,
            sort: "id:desc",
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupMessageTemplates.list(...params);

        this.isLoading = false;

        this.messageTemplates = response.data.data;

        if (this.queryTemplateId) {
          const active = this.messageTemplates.find(
            (el) => el.id === Number(this.queryTemplateId),
          );

          this.messageTemplate = this.messageTemplates.find(
            (el) => el.id == active.id,
          );

          this.$router.replace({ query: null }, () => {});
        }

        this.listGroupUserMessages();
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    async listGroupUserMessages() {
      try {
        const params = [
          this.groupId,
          {
            ...this.transformedFilters,
            with_recipient: 1,
            page: this.page,
            per_page: this.perPage,
            sort: "id:desc",
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupUserMessages.list(...params);

        this.isLoading = false;

        this.messages = response.data.data;
        this.totalPages = response.data.pagination.total_pages;
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    async updateEmailStatuses() {
      try {
        const params = [this.groupId, this.messageTemplate.id];

        this.isLoading = true;

        await this.$api.groupMessageTemplates.update_email_statuses(...params);

        this.listGroupUserMessages();
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    formatDate(date) {
      if (!date) return;
      return moment.tz(date, this.userTimezone).format("DD-MMMM-YYYY H:mm");
    },
    getLangIcon(langKey) {
      const icon = this.languageIcons.find((el) => el.key === langKey);
      return !icon ? "" : icon.iso2;
    },
    async exportMessages(format) {
      try {
        this.isLoading = true;

        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}groups/${this.groupId}/users/messages_export`,
          params: {
            format: format,
            ...this.transformedFilters,
          },
          method: "POST",
          responseType: "blob",
        });

        this.isLoading = false;

        fileDownload(response.data, `messages_report.${format}`);
      } catch (error) {
        this.isLoading = false;

        this.$store.dispatch("addErrorNotification", {
          message:
            error & error.response &&
            error.response.data &&
            error.response.data.error
              ? error.response.data.error.message
              : this.$t("internalServerError"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}

.status {
  &-sent {
    color: #2ca8a4;
  }
  &-not_sent {
    color: #e799af;
  }
  &-unknown {
    color: #fff2bb;
  }
}

.tooltip-content {
  max-width: 300px;
}
</style>
